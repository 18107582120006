import {DisputeHandlingStatus} from "../../../declarations";
import {Chip} from "../Chip";
import {InputUtils} from "../../../helpers";

const getDisputeChip = (handlingStatus: DisputeHandlingStatus) => {
  const handlingStatusString = InputUtils.prettierSnakeCase(handlingStatus);
  if (handlingStatus === "pending") {
    return <Chip label={handlingStatusString} variant_={"warning"} border size={"medium"} />;
  }
  if (handlingStatus === "closed") {
    return <Chip label={handlingStatusString} variant_={"success"} border size={"medium"} />;
  }
  if (handlingStatus === "action_required") {
    return <Chip label={handlingStatusString} variant_={"error"} border size={"medium"} />;
  } else {
    return <Chip label={handlingStatusString} variant_={"inherit"} border size={"medium"} />;
  }
};

export {getDisputeChip};
