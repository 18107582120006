import {experimentalStyled as styled} from "@mui/material/styles";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Colors} from "../../colors";

export const DataGridProStyled = styled(DataGridPro)(({theme}) => ({
  width: "100%",
  border: `1px solid ${Colors.tertiary._300}`,
  backgroundColor: Colors.bw.white,
  borderRadius: "12px",
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "12px",
    fontWeight: 600,
    color: Colors.grey._1000,
  },
}));
