import React, {useCallback} from "react";
import {IconMenuItem} from "@bounce/shared/ui";
import {IconLink} from "../../../../bounce-ui/consumer";
import NiceModal from "@ebay/nice-modal-react";
import {Pages} from "../../../../bi-events/enum";
import {useAmplitude} from "../../../../contexts";
import GenerateContributionLinkDialog from "../../../../pages/account/payment/guest-payment/GenerateContributionLinkDialog";
import {AccountExternal} from "../../../../declerations/server/account_models";

interface ISupportItem {
  account: AccountExternal;
  closeHandler: () => void;
}

const ShareContributionLink = ({account, closeHandler}: ISupportItem) => {
  const {pageClickEvent} = useAmplitude();
  const totalBalance = account.active_payment_plan ? account.active_payment_plan.total_amount : account?.total_balance;

  const handleShareLink = useCallback((): void => {
    pageClickEvent({page: Pages.MENU, cta: "share_contribution_link"});
    closeHandler();
    NiceModal.show(GenerateContributionLinkDialog, {
      totalBalance,
      accountId: account.id,
    });
  }, [totalBalance]);

  return <IconMenuItem label="Share contribution link" icon={<IconLink />} onClick={handleShareLink} />;
};

export default ShareContributionLink;
