import {axiosSpringInstance} from "../../../ServerApiManager";
import {AxiosResponse} from "axios";
import {BaseGeneratorVariable, ImportResponse} from "../admin-panel/utils";
export const generatePricingWashReport = async (variables: BaseGeneratorVariable): Promise<ImportResponse> => {
  const result = await axiosSpringInstance.post<string, AxiosResponse<ImportResponse>>(
    `/pricing/wash/generate/${variables.reportType}?batch=${variables.batch}`
  );

  return result.data;
};
