import React from "react";
import {ActivityData, EmailData, LetterData, PhoneCallData, SMSData} from "../../../declarations";
import {extractUTCDateFromString, InputUtils, SHORT_TIMESTAMP_FORMAT} from "../../../helpers";
import {get, isEmpty} from "lodash";
import {
  StyledText,
  CallContactColorsMap,
  getSuffixStatusLabel,
  IconAttachment,
  IconCheck,
  IconError,
} from "../../../ui";

export interface IActivityCard {
  activity: ActivityData;
}

export const TitleExtraInfo = ({activity}: IActivityCard) => {
  const {channel, event_type} = activity;

  if (channel === "phone_call") {
    const {call_contact} = activity as PhoneCallData;
    if (call_contact) {
      const color = get(Object.fromEntries(CallContactColorsMap()), call_contact)?.color;
      return (
        <>
          {color && (
            <div
              style={{
                width: "14px",
                height: "14px",
                backgroundColor: color,
                borderRadius: "50%",
              }}
            />
          )}
          <StyledText value={InputUtils.prettierSnakeCase(call_contact)} />
        </>
      );
    }
  }
  if (["SMS_UNDELIVERED", "PAYMENT_FAILED", "PAYMENT_FAILED_RETRY", "PAYMENT_CHARGEBACK"].includes(event_type)) {
    return <IconError />;
  }
  if (["SMS_DELIVERED"].includes(event_type)) {
    const {status, status_time} = activity as SMSData;
    if (status && status_time) {
      return (
        <StyledText
          size={12}
          value={`| Last status: ${getSuffixStatusLabel("SMS_", status)} (${extractUTCDateFromString(
            status_time,
            SHORT_TIMESTAMP_FORMAT
          )})`}
        />
      );
    }
  }
  if (event_type === "PAYMENT_RECEIVED") {
    return <IconCheck />;
  }
  if (["EMAIL_SENT", "EMAIL_DELIVERED", "EMAIL_OPEN", "EMAIL_CLICK", "EMAIL_UNSUBSCRIBE"].includes(event_type)) {
    const {attachments, status, status_time} = activity as EmailData;
    return (
      <>
        {!isEmpty(attachments) && <IconAttachment />}
        {event_type === "EMAIL_DELIVERED" && status && status_time && (
          <StyledText
            size={12}
            value={`| Last status: ${getSuffixStatusLabel("EMAIL_", status)} (${extractUTCDateFromString(
              status_time
            )})`}
          />
        )}
      </>
    );
  }
  if (["LETTER_SENT"].includes(event_type)) {
    const {status, status_time} = activity as LetterData;
    return (
      <>
        {status && status_time && (
          <StyledText
            size={12}
            value={`| Last status: ${getSuffixStatusLabel("LETTER_", status)} (${extractUTCDateFromString(
              status_time
            )})`}
          />
        )}
      </>
    );
  }
  return null;
};
