import {
  ContactValidityStatus,
  DisputeCannedResponseChannelType,
  DisputeCannedResponseType,
  EmailType,
  EmploymentRole,
  LetterTemplateKey,
  PhoneType,
  ReSkill,
} from "declerations/server";
import {FileType, GenerateFileType, EnumInfo, EnumInfoMap} from "@bounce/shared/declarations";
import {
  BankAccountType,
  CommunicationActionType,
  DSCActionType,
  DSCPaymentPlanFileFormatTypes,
  DSCPaymentsFileFormatTypes,
  LenderId,
  LitigationActionType,
  PricingAccountActionType,
  ReportType,
  UserActionType,
  UserLevelMediaActions,
} from "../declerations/enums";
import {ContactOptions, OutcomeOptions} from "../declerations/server/call_outcome_models";
import {BatchFileType} from "../declerations/server/lender_models";
import {PaymentPlanFrequency} from "@bounce/shared/enums";

export const getEnumLabelKey = <T>(map: EnumInfoMap<T>, value?: T): string => {
  if (!value) {
    return "notAvailable";
  }
  return map.get(value)?.labelKey || "notAvailable";
};

export const bankAccountTypeInfoMap: EnumInfoMap<BankAccountType> = new Map<BankAccountType, EnumInfo>([
  ["PersonalChecking", {labelKey: "enumBankAccountTypePersonalChecking"}],
  ["PersonalSavings", {labelKey: "enumBankAccountTypePersonalSavings"}],
  ["BusinessChecking", {labelKey: "enumBankAccountTypeBusinessChecking"}],
  ["Unknown", {labelKey: "enumBankAccountTypeUnknown"}],
]);

export const paymentPlanFrequencyInfoMap: EnumInfoMap<PaymentPlanFrequency> = new Map<PaymentPlanFrequency, EnumInfo>([
  ["weekly", {labelKey: "weekly"}],
  ["bi_weekly", {labelKey: "biWeekly"}],
  ["monthly", {labelKey: "monthly"}],
  ["one_time", {labelKey: "oneTime"}],
  ["first_and_fifteenth", {labelKey: "firstAndFifteenth"}],
  ["non_linear", {labelKey: "nonLinear"}],
]);

export const EmailTypeMap: EnumInfoMap<EmailType> = new Map<EmailType, EnumInfo>([
  ["PERSONAL", {labelKey: "Personal"}],
  ["WORK", {labelKey: "Work"}],
  ["JUNK", {labelKey: "Junk"}],
  ["UNKNOWN", {labelKey: "Unknown"}],
]);

export const PhoneTypeMap: EnumInfoMap<PhoneType> = new Map<PhoneType, EnumInfo>([
  ["CELL", {labelKey: "Cell"}],
  ["WORK", {labelKey: "Work"}],
  ["HOME", {labelKey: "Home"}],
  ["FAX", {labelKey: "Fax"}],
  ["UNKNOWN", {labelKey: "Unknown"}],
]);
export const ContactValidityStatusTypeMap: EnumInfoMap<ContactValidityStatus> = new Map<
  ContactValidityStatus,
  EnumInfo
>([
  ["VALID", {labelKey: "Valid"}],
  ["INVALID", {labelKey: "Invalid"}],
  ["UNKNOWN", {labelKey: "Unknown"}],
  ["NOT_VALIDATED", {labelKey: "Not Validated"}],
]);

export const accountFlagNames = [
  "active_complaint",
  "creditor_recalled",
  "fraud_reported",
  "cease_and_desist",
  "freeze_communication",
  "closed",
];

export const userFlagNames = [
  "hearing_disability",
  "reading_disability",
  "active_military",
  "deceased",
  "unhandled_communication",
];

export type UserFlags = (typeof userFlagNames)[number];
export type AccountFlags = (typeof accountFlagNames)[number];

export const LetterTemplateKeyTypeMap: EnumInfoMap<LetterTemplateKey> = new Map<LetterTemplateKey, EnumInfo>([
  ["OTHER", {labelKey: "Other"}],
  ["DISPUTE_RESPONSE", {labelKey: "Dispute Response"}],
]);

export const BatchFileTypeMap: EnumInfoMap<BatchFileType> = new Map<BatchFileType, EnumInfo>([
  ["affidavit_of_sale", {labelKey: "Affidavit Of Sale"}],
  ["bill_of_sale", {labelKey: "Bill Of Sale"}],
  ["certificate_of_conformity", {labelKey: "Certificate of Conformity"}],
]);

export const FileTypeMap: EnumInfoMap<FileType> = new Map<FileType, EnumInfo>([
  ["unknown", {labelKey: "Unknown"}],
  ["dispute", {labelKey: "Dispute"}],
  ["phone_call_recording", {labelKey: "Phone Call Recording"}],
  ["phone_call_transcription", {labelKey: "Phone Call Transcription"}],
  ["incoming_email_attachment", {labelKey: "Incoming Email Attachment"}],
  ["cease_and_desist_notice", {labelKey: "C&D Notice"}],
  ["bankruptcy", {labelKey: "Bankruptcy"}],
  ["account_statement", {labelKey: "Account Statement"}],
  ["contract", {labelKey: "Contract"}],
  ["borrower_membership_agreement", {labelKey: "Borrower Membership Agreement"}],
  ["transaction_history", {labelKey: "Transaction History"}],
  ["redacted_assignment", {labelKey: "Redacted Assignment"}],
  ["terms_and_conditions", {labelKey: "Terms and Conditions"}],
  ["consent_form", {labelKey: "Consent Form"}],
  ["representation_notice", {labelKey: "Representation Notice"}],
  ["bank_statement", {labelKey: "Bank Statement"}],
  ["general", {labelKey: "General"}],
  ["lawsuit", {labelKey: "Lawsuit"}],
  ["complaint", {labelKey: "Complaint"}],
]);

export const outcomeOptionsMap: Map<OutcomeOptions, string> = new Map<OutcomeOptions, string>([
  ["hang_up", "Hang Up"],
  ["no_payment", "No Payment"],
  ["dispute", "Dispute"],
  ["bankruptcy", "Bankruptcy"],
  ["fraud", "Fraud"],
  ["payment", "Payment"],
  ["promise", "Promise"],
  ["payment_plan_updated", "Payment Plan Updated"],
  ["represented", "Represented"],
  ["cease_and_desist", "C&D / Refusal"],
  ["requested_callback", "Requested Callback"],
  ["information_confirmed", "Information Confirmed"],
  ["information_corrected", "Information Corrected"],
  ["message_left", "Message Left"],
  ["no_message_left", "No Message Left"],
  ["possible_rpc", "Possible RPC"],
  ["wrong_number", "Wrong Number"],
  ["call_transferred", "Call Transferred"],
  ["escalated_to_supervisor", "Escalated To Supervisor"],
  ["escalated_to_senior_re", "Escalated To Senior RE"],
]);

export const ContactOptionsMap: Map<ContactOptions, string> = new Map<ContactOptions, string>([
  ["right_party_contact", "Right Party Contact"],
  ["third_party_contact", "Third Party Contact"],
  ["no_answer", "No Answer"],
  ["voicemail", "voicemail"],
  ["unverified_contact", "Unverified Contact"],
  ["wrong_number", "Wrong Number"],
  ["disconnected_number", "Disconnected Number"],
]);

export const LenderIdInfoMap: EnumInfoMap<LenderId> = new Map<LenderId, EnumInfo>([
  ["bounce_qa", {labelKey: "Quality Acceptance"}],
  ["bounce_af", {labelKey: "Advance Financial"}],
  ["bounce_jetty", {labelKey: "Jetty National"}],
  ["bounce_tally", {labelKey: "Tally"}],
  ["bounce_lvble", {labelKey: "Lvble"}],
  ["bounce_proceed", {labelKey: "Proceed"}],
  ["bounce_lusa", {labelKey: "LendingUSA"}],
  ["bounce_guardian_credit_union", {labelKey: "Guardian Credit Union"}],
  ["bounce_qira", {labelKey: "Qira"}],
  ["bounce_zebit", {labelKey: "Zebit"}],
  ["bounce_dignifi", {labelKey: "DigniFi"}],
  ["bounce_paytomorrow", {labelKey: "PayTomorrow"}],
  ["bounce_happy_money", {labelKey: "Happy Money"}],
  ["bounce_happy_money_samba", {labelKey: "Happy Money"}],
  ["bounce_vw_cu", {labelKey: "Vantage West CU"}],
  ["bounce_ivitafi", {labelKey: "iVitaFi"}],
  ["bounce_arro", {labelKey: "Arro"}],
  ["bounce_rentapp", {labelKey: "Rent App"}],
]);
export const UserLevelMediaActionsInfoMap: EnumInfoMap<UserLevelMediaActions> = new Map<
  UserLevelMediaActions,
  EnumInfo
>([
  ["originator_transaction_history", {labelKey: "Originator Transaction History"}],
  ["redacted_assignment", {labelKey: "Redacted Assignment"}],
  ["upload_media", {labelKey: "Upload Other Media"}],
]);

export const UserLevelMediaActionsToMediaUploadTypeMap: EnumInfoMap<UserLevelMediaActions> = new Map<
  UserLevelMediaActions,
  EnumInfo
>([
  ["originator_transaction_history", {labelKey: "ORIGINATOR_TRANSACTION_HISTORY"}],
  ["redacted_assignment", {labelKey: "REDACTED_ASSIGNMENT"}],
  ["upload_media", {labelKey: "OTHER_MEDIA"}],
]);

export const UserImportActionType: EnumInfoMap<UserActionType> = new Map<UserActionType, EnumInfo>([
  ["user_import", {labelKey: "Import Users"}],
  ["user_update_import", {labelKey: "Update Users"}],
  ["add_phones_import", {labelKey: "Add Phones"}],
  ["add_emails_import", {labelKey: "Add Emails"}],
  ["returned_letters_import", {labelKey: "Returned Letters"}],
  ["remove_account_import", {labelKey: "Remove Accounts"}],
  ["settle_account_import", {labelKey: "Settle Accounts"}],
  ["unfreeze_communication_import", {labelKey: "Unfreeze Communication"}],
  ["freeze_communication_import", {labelKey: "Freeze Communication"}],
  ["credit_report_accounts_deletion", {labelKey: "Delete credit report accounts"}],
]);

export const DSCImportActionType: EnumInfoMap<DSCActionType> = new Map<DSCActionType, EnumInfo>([
  ["dsc_wash", {labelKey: "DSCPanel Wash"}],
  ["dsc_update", {labelKey: "DSCPanel Update"}],
  ["general_dsc_wash", {labelKey: "DSCPanel General Wash"}],
  ["import_dsc_payment_plans", {labelKey: "Import Payment Plans"}],
  ["import_dsc_payments", {labelKey: "Import Payments"}],
  ["dsc_enrollment", {labelKey: "Enroll Accounts To DSCPanel"}],
  ["dsc_enrollment_in_autopay", {labelKey: "Enroll in autopay"}],
]);
export const LitigationImportActionType: EnumInfoMap<LitigationActionType> = new Map<LitigationActionType, EnumInfo>([
  ["assign_to_legal", {labelKey: "Assign To Legal"}],
  ["chain_of_title", {labelKey: "Generate Chain of Title Documents"}],
]);
export const DSCPaymentPlanImportFileFormat: EnumInfoMap<DSCPaymentPlanFileFormatTypes> = new Map<
  DSCPaymentPlanFileFormatTypes,
  EnumInfo
>([
  ["default_dsc_payment_plan_import", {labelKey: "Default"}],
  ["freedom_debt_relief_dsc_payment_plan_import", {labelKey: "Freedom Debt Relief"}],
]);

export const DSCPaymentsImportFileFormat: EnumInfoMap<DSCPaymentsFileFormatTypes> = new Map<
  DSCPaymentsFileFormatTypes,
  EnumInfo
>([["cft_pay", {labelKey: "CFTPay"}]]);

export const PricingAccountImportAction: EnumInfoMap<PricingAccountActionType> = new Map<
  PricingAccountActionType,
  EnumInfo
>([
  ["pricing_account_import", {labelKey: "Import Accounts for Pricing"}],
  ["delete_pricing_account", {labelKey: "Delete Pricing Accounts"}],
  ["import_pricing_wash", {labelKey: "Import Pricing Wash Report"}],
  ["dsc_pricing_wash", {labelKey: "DSC Pricing Wash "}],
]);

type UserActionToColumnNameType = Record<
  UserActionType | PaymentActionType | CommunicationActionType | DSCActionType | LitigationActionType,
  string
>;
export const UserActionToColumnName: UserActionToColumnNameType = {
  user_import: "add_user",
  user_update_import: "update_user",
  add_phones_import: "add_phones",
  add_emails_import: "add_emails",
  returned_letters_import: "letter_returned",
  remove_account_import: "remove_account",
  settle_account_import: "settle_account",
  unfreeze_communication_import: "unfreeze_communication",
  freeze_communication_import: "freeze_communication",
  payment_import: "add_payment",
  reverse_payment_import: "reverse_payment",
  deactivate_payment_plan_import: "deactivate_payment_plan",
  user_wash: "",
  credit_report_accounts_deletion: "credit_report_accounts_deletion",
  anonymous_interaction_reconciliation: "anonymous_interaction_reconciliation",
  dsc_wash: "dsc_wash",
  dsc_update: "dsc_update",
  import_dsc_payment_plans: "import_dsc_payment_plans",
  dsc_enrollment: "dsc_enrollment",
  dsc_enrollment_in_autopay: "dsc_enrollment_in_autopay",
  assign_to_legal: "promote_litigation",
  chain_of_title: "chain_of_title",
  general_dsc_wash: "",
  user_based_ca_scores: "user_based_ca_scores",
  import_dsc_payments: "import_dsc_payments",
};

export type PaymentActionType = "payment_import" | "reverse_payment_import" | "deactivate_payment_plan_import";
export const PaymentImportActionType: EnumInfoMap<PaymentActionType> = new Map<PaymentActionType, EnumInfo>([
  ["payment_import", {labelKey: "Import Payments"}],
  ["reverse_payment_import", {labelKey: "Reverse Payments"}],
  ["deactivate_payment_plan_import", {labelKey: "Deactivate Payment Plans"}],
]);

export const ReportTypeInfoMap: EnumInfoMap<ReportType> = new Map<ReportType, EnumInfo>([
  ["EXPERIAN_COLLECTION_ADVANTAGE", {labelKey: "EXPERIAN_COLLECTION_ADVANTAGE"}],
  ["EXPERIAN_ADDRESS_WASHING", {labelKey: "EXPERIAN_ADDRESS_WASHING"}],
  ["EXPERIAN_TRUE_TRACE_PHONE_WASHING", {labelKey: "EXPERIAN_TRUE_TRACE_PHONE_WASHING"}],
  ["EXPERIAN_COLLECTION_ADVANTAGE_AND_CONSUMER_INFO", {labelKey: "EXPERIAN_COLLECTION_ADVANTAGE_AND_CONSUMER_INFO"}],
  ["SCRA_WASHING", {labelKey: "SCRA_WASHING"}],
]);

export const PricingReportTypeInfoMap: EnumInfoMap<ReportType> = new Map<ReportType, EnumInfo>([
  ["EXPERIAN_COLLECTION_ADVANTAGE", {labelKey: "EXPERIAN_COLLECTION_ADVANTAGE"}],
]);

export const EmploymentRolesTypeInfoMap: EnumInfoMap<EmploymentRole> = new Map<EmploymentRole, EnumInfo>([
  ["past_employee", {labelKey: "Past Employee"}],
  ["resolution_expert", {labelKey: "Resolution Expert"}],
  ["supervisor", {labelKey: "Supervisor"}],
  ["bounce_general", {labelKey: "Bounce General"}],
  ["call_center_operation", {labelKey: "Call Center Operation"}],
]);

export const ReSkillsTypeInfoMap: EnumInfoMap<ReSkill> = new Map<ReSkill, EnumInfo>([
  ["dsc", {labelKey: "DSC"}],
  ["spanish", {labelKey: "Spanish"}],
  ["pre_legal", {labelKey: "Pre-Legal"}],
]);

export const DisputeCannedResponseTypeInfoMap: EnumInfoMap<DisputeCannedResponseType> = new Map<
  DisputeCannedResponseType,
  EnumInfo
>([
  ["generic_dispute_response", {labelKey: "Generic Dispute Response"}],
  ["fraud_claims_missing_files", {labelKey: "Fraud Claims - Missing Files"}],
  ["returning_to_creditor", {labelKey: "Returning To Creditor"}],
]);

export const DisputeCannedResponseChannelTypeInfoMap: EnumInfoMap<DisputeCannedResponseChannelType> = new Map<
  DisputeCannedResponseChannelType,
  EnumInfo
>([
  ["email", {labelKey: "Email"}],
  ["sms", {labelKey: "SMS"}],
]);
export const GenerateFileTypeMap: EnumInfoMap<GenerateFileType> = new Map<GenerateFileType, EnumInfo>([
  ["DEBT_BUYING_DISPUTE_RESPONSE", {labelKey: "Dispute Response (Debt Purchase) - Documents Included"}],
  ["DEBT_BUYING_DISPUTE_RESPONSE_NO_DOCS", {labelKey: "Dispute Response (Debt Purchase) - No Documents"}],
  ["THIRD_PARTY_DISPUTE_RESPONSE", {labelKey: " Dispute Response (3rd Party) - Documents Included"}],
  ["THIRD_PARTY_DISPUTE_RESPONSE_NO_DOCS", {labelKey: "Dispute Response (3rd Party) - No Documents"}],
  ["JETTY_DISPUTE_RESPONSE", {labelKey: " Dispute Response (Jetty) - Documents Included"}],
  ["JETTY_DISPUTE_RESPONSE_NO_DOCS", {labelKey: "Dispute Response (Jetty) - No Documents"}],
  ["POC_ATTACHMENT", {labelKey: "POC Attachment"}],
  ["ACCOUNT_RESOLVED", {labelKey: "Account Resolved"}],
  ["BALANCE_STATEMENT", {labelKey: "Balance Statement"}],
  ["DSC_PLAN_ONE_TIME_SETTLEMENT", {labelKey: "DSC Settlement - One Time"}],
  ["DSC_PLAN_SETTLEMENT", {labelKey: "DSC Settlement"}],
  ["BOUNCE_TRANSACTION_HISTORY", {labelKey: "Bounce Transaction History"}],
  ["CHAIN_OF_TITLE", {labelKey: "Chain of Title"}],
]);

export const ContactOptionsTypeInfoMap: EnumInfoMap<ContactOptions> = new Map<ContactOptions, EnumInfo>([
  ["right_party_contact", {labelKey: "Right Party Contact"}],
  ["third_party_contact", {labelKey: "Third Party Contact"}],
  ["no_answer", {labelKey: "No Answer"}],
  ["voicemail", {labelKey: "Voicemail"}],
  ["unverified_contact", {labelKey: "Unverified Contact"}],
  ["wrong_number", {labelKey: "Wrong Number"}],
  ["disconnected_number", {labelKey: "Disconnected Number"}],
]);

export const OutcomeOptionsTypeInfoMap: {value: OutcomeOptions; label: string}[] = [
  {value: "hang_up", label: "Hang Up"},
  {value: "no_payment", label: "No Payment"},
  {value: "dispute", label: "Dispute"},
  {value: "bankruptcy", label: "Bankruptcy"},
  {value: "fraud", label: "Fraud"},
  {value: "payment", label: "Payment"},
  {value: "promise", label: "Promise"},
  {value: "payment_plan_updated", label: "Payment Plan Updated"},
  {value: "represented", label: "Represented"},
  {value: "cease_and_desist", label: "Cease and Desist"},
  {value: "requested_callback", label: "Requested Callback"},
  {value: "information_confirmed", label: "Information Confirmed"},
  {value: "information_corrected", label: "Information Corrected"},
  {value: "message_left", label: "Message Left"},
  {value: "no_message_left", label: "No Message Left"},
  {value: "no_information_provided", label: "No Information Provided"},
  {value: "possible_rpc", label: "Possible RPC"},
  {value: "wrong_number", label: "Wrong Number"},
  {value: "call_transferred", label: "Call Transferred"},
  {value: "escalated_to_supervisor", label: "Escalated to Supervisor"},
  {value: "escalated_to_senior_re", label: "Escalated to Senior REx"},
];

export const ContactToSummaryOutcomeMap: Map<ContactOptions, OutcomeOptions[]> = new Map<
  ContactOptions,
  OutcomeOptions[]
>([
  [
    "right_party_contact",
    [
      "requested_callback",
      "payment",
      "promise",
      "payment_plan_updated",
      "escalated_to_supervisor",
      "escalated_to_senior_re",
      "dispute",
      "bankruptcy",
      "fraud",
      "represented",
      "no_payment",
      "hang_up",
      "cease_and_desist",
    ],
  ],
  [
    "third_party_contact",
    ["information_confirmed", "information_corrected", "message_left", "no_information_provided"],
  ],
  ["no_answer", []],
  ["voicemail", ["message_left", "no_message_left"]],
  ["unverified_contact", ["requested_callback", "possible_rpc", "call_transferred", "wrong_number", "hang_up"]],
  ["wrong_number", []],
  ["disconnected_number", []],
]);
