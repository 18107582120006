import React, {FunctionComponent, useEffect} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Link} from "@mui/material";
import {IconBackArrow} from "../../assets";
import {Colors, Container, LoadingCenter, Text} from "@bounce/shared/ui";
import {DisputeType, FileRead} from "../../../declarations";
import {extractUTCDateFromString, InputUtils, TIMESTAMP_FORMAT_V5} from "../../../helpers";
import {map, isUndefined, isEmpty} from "lodash";

interface DisputeInformationProps {
  accountId?: string;
  userId?: string;
  disputeType: DisputeType;
  disputeMessage?: string;
  fetchDisputeFiles: (fileIds: string[], userId?: string, accountId?: string) => any;
  fileIds?: string[];
  otherDisputesFileIds?: string[];
  respondedDetails?: {respondedAt: string; respondedPrincipal: string};
  closeDetails?: {closedAt: string; closePrincipal: string; closedReason?: string; disputeConclusion: string};
}
export const DisputeInformationAccordion: FunctionComponent<DisputeInformationProps> = ({
  disputeType,
  disputeMessage,
  fetchDisputeFiles,
  fileIds,
  otherDisputesFileIds,
  respondedDetails,
  closeDetails,
  accountId,
  userId,
}) => {
  const [files, setFiles] = React.useState<FileRead[]>();
  const [otherDisputesFiles, setOtherDisputesFiles] = React.useState<FileRead[]>();

  useEffect(() => {
    if (fileIds && !isEmpty(fileIds)) {
      const getDisputeFiles = async () => {
        return fetchDisputeFiles(fileIds, userId, accountId);
      };
      getDisputeFiles().then((files: FileRead[]) => {
        setFiles(files);
      });
    }
    if (otherDisputesFileIds && !isEmpty(otherDisputesFileIds)) {
      const getDisputeFiles = async () => {
        return fetchDisputeFiles(otherDisputesFileIds, userId, accountId);
      };
      getDisputeFiles().then((files: FileRead[]) => {
        setOtherDisputesFiles(files);
      });
    }
  }, []);

  if (
    (isUndefined(files) && !isEmpty(fileIds)) ||
    (isUndefined(otherDisputesFiles) && !isEmpty(otherDisputesFileIds))
  ) {
    return <LoadingCenter />;
  }

  return (
    <Accordion sx={{backgroundColor: Colors.tertiary._100, width: "80%"}}>
      <AccordionSummary expandIcon={<IconBackArrow style={{transform: "rotate(-90deg)"}} />}>
        <Text size={16} fontWeight="medium" color={Colors.grey._600}>
          Dispute information
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Container fullWidth sx={{gap: "20px", alignItems: "flex-start"}}>
          <Text>
            <Text size={12} fontWeight="medium" color={Colors.grey._400}>
              Dispute type
            </Text>
            <Text size={14}>{InputUtils.prettierSnakeCase(disputeType)}</Text>
          </Text>
          <Text>
            <Text size={12} fontWeight="medium" color={Colors.grey._400}>
              Message
            </Text>
            <Text size={14}>{disputeMessage}</Text>
          </Text>
          {!isEmpty(files) && <DisputeFilesList files={files} title="Attached files" />}
          {!isEmpty(otherDisputesFiles) && (
            <DisputeFilesList files={otherDisputesFiles} title="Files from other disputes" />
          )}
          {respondedDetails && (
            <Text>
              <Text size={12} fontWeight="medium" color={Colors.grey._400}>
                Responded
              </Text>
              <Text size={14}>{`${extractUTCDateFromString(respondedDetails.respondedAt, TIMESTAMP_FORMAT_V5)}, by ${
                respondedDetails.respondedPrincipal
              }`}</Text>
            </Text>
          )}
          {closeDetails && (
            <>
              <Text>
                <Text size={12} fontWeight="medium" color={Colors.grey._400}>
                  Closed
                </Text>
                <Text size={14}>{`Dispute conclusion: ${closeDetails.disputeConclusion}`}</Text>
                <Text size={14}>{`Closed reason: ${closeDetails.closedReason}`}</Text>
                <Text size={14}>{`${extractUTCDateFromString(closeDetails.closedAt, TIMESTAMP_FORMAT_V5)}, by ${
                  closeDetails.closePrincipal
                }`}</Text>
              </Text>
            </>
          )}
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

const DisputeFilesList = ({files, title}: {files?: FileRead[]; title: string}) => {
  if (!files) {
    return null;
  }

  return (
    <Text>
      <Text size={12} fontWeight="medium" color={Colors.grey._400}>
        {title}
      </Text>
      {map(files, (file, index) => {
        return (
          <Link key={file.id} sx={{textDecoration: "none"}}>
            <Text
              size={14}
              sx={{
                whiteSpace: "break-spaces",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {file.filename}
            </Text>
          </Link>
        );
      })}
    </Text>
  );
};
