import {
  IconBankruptcy,
  IconCallCenter,
  IconDSC,
  IconPartner,
  IconInventory,
  IconSearchBig,
  IconLitigation,
  IconKey,
} from "../../bounce-ui/assets";
import {urls} from "../../routes/urls";
import {Colors, MenuListItem, IconDispute} from "@bounce/shared/ui";

export const springMenuItems: MenuListItem[] = [
  {
    id: "search",
    type: "category-item",
    title: "search",
    icon: <IconSearchBig color={Colors.primary._300} />,
    url: urls.searchSpring.url,
  },
  {
    id: "lender-operations",
    type: "category-nest-item",
    title: "lender operations",
    icon: <IconKey />,
    subListItems: [
      {id: "import-users", title: "users", url: urls.importUsers.url},
      {id: "import-payments", title: "payments", url: urls.importPayments.url},
      {id: "washing", title: "washing", url: urls.washing.url},
      {id: "user-level-media", title: "User-level Media", url: urls.userLevelMedia.url},
      {id: "batch-level-media", title: "Batch-level Media", url: urls.batchLevelMedia.url},
      {id: "import-pricing", title: "pricing - Import", url: urls.importPricing.url},
      {id: "generate-pricing", title: "pricing - Batch", url: urls.generatePricing.url},
    ],
  },
  {
    id: "debt settlement",
    type: "category-nest-item",
    title: "debt settlement",
    icon: <IconDSC />,
    subListItems: [
      {id: "overview", title: "overview", url: urls.dscOverview.url},
      {id: "bulk-operations", title: "bulk operations", url: urls.dscBulkOperations.url},
      {id: "anonymous-communications", title: "anonymous comms", url: urls.anonymousCommunications.url},
      {id: "representation-companies", title: "companies", url: urls.representationCompanies.url},
    ],
  },
  {
    id: "inventory",
    type: "category-item",
    title: "inventory",
    icon: <IconInventory color={Colors.primary._300} />,
    url: urls.inventory.url,
  },
  {
    id: "disputes",
    type: "category-item",
    title: "Disputes",
    icon: <IconDispute />,
    url: urls.disputes.url,
  },
  {
    id: "bankruptcies",
    type: "category-item",
    title: "bankruptcies",
    icon: <IconBankruptcy />,
    url: urls.bankruptcies.url,
  },
  {
    id: "litigation",
    type: "category-nest-item",
    title: "Litigation",
    icon: <IconLitigation />,
    subListItems: [{id: "litigation-import", title: "Bulk operations", url: urls.litigationBulkOperation.url}],
  },
  {
    id: "partner-platform",
    type: "category-nest-item",
    title: "Partner Platform",
    icon: <IconPartner />,
    subListItems: [
      {id: "partner-bulk-operations", title: "Bulk operations", url: urls.partnerPlatformBulkOperation.url},
      {id: "reports", title: "Reports", url: urls.partnerPlatformReports.url},
    ],
  },
  {
    id: "call-center",
    type: "category-nest-item",
    title: "call center",
    icon: <IconCallCenter />,
    subListItems: [
      {id: "rex-management", title: "REx management", url: urls.rexManagement.url},
      {id: "conversations", title: "conversations", url: urls.conversationsSpring.url},
    ],
  },
];
