import {FileFormatTypes, ReportType} from "../../../../declerations/enums";

export type BaseImportVariable = {
  file: File;
  importType: string;
  fileFormat?: FileFormatTypes;
  settings?: {
    report_date?: string;
    transaction_date?: string;
    report_description?: string;
    report_type?: ReportType;
    file_format?: FileFormatTypes;
    override_details?: boolean;
  };
};

export type BaseGeneratorVariable = {
  reportType?: ReportType;
  batch?: string;
};

export type ImportResponse = {
  file_id?: number;
  import_type?: ReportType;
};
export function setDownloadFileAttributes(fileData: Blob, fileName: string) {
  const href = URL.createObjectURL(fileData);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
