import React, {FunctionComponent} from "react";
import {Container} from "@mui/material";
import {experimentalStyled as styled} from "@mui/material/styles";
import UserDetail from "./UserDetail";
import {map} from "lodash";
import {Colors, Text, ImgProfile, IconArrowTopRight, Chip} from "@bounce/shared/ui";
import {SearchUserRead} from "@bounce/shared/declarations";

interface UserPreviewProps {
  user: SearchUserRead;
  onClick: () => void;
  borderBottom?: string;
  numberOfItems: number;
  index: number;
}

const RootStyle = styled(Container)<{borderbottom?: string}>(({theme, borderbottom}) => ({
  width: "600px",
  borderBottom: borderbottom ? `1px solid ${borderbottom}` : undefined,
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  gap: "12px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: Colors.primary._100,
  },
}));

const UserTitle = styled("div")(({theme}) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

const UserName = styled("div")(({theme}) => ({
  display: "flex",
  gap: "12px",
  fontSize: "14px",
  alignItems: "center",
}));

const UserDetails = styled("div")(({theme}) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr 1fr",
  height: "70px",
  color: theme.palette.common.black,
}));
const getUserDetails = (user: SearchUserRead) => {
  return [
    {detailName: "SSN", detail: user.ssn},
    {detailName: "Bounce reference #", detail: user.account_info?.public_id},
    {detailName: "Email", detail: user.email},
    {detailName: "Original Account", detail: user.account_info?.external_account_id},
    {detailName: "Phone", detail: user.phone},
    {detailName: "Original Creditor", detail: user.account_info?.original_creditor},
  ];
};

const UserPreview: FunctionComponent<UserPreviewProps> = ({user, onClick, borderBottom, numberOfItems, index}) => {
  if (!user) return null;

  return (
    <RootStyle onClick={onClick} borderbottom={numberOfItems - 1 === index ? undefined : borderBottom}>
      <UserTitle>
        <UserName>
          <ImgProfile style={{width: "25px", height: "25px"}} />
          <Text variant={"h3"}>
            {`${user.first_name} ${user.middle_name ? user.middle_name : ""} ${user.last_name}`}
          </Text>
          {user.num_of_accounts > 1 && <Chip label={"Multi-account"} variant_={"secondary"} size_={"medium"} border />}
        </UserName>
        <IconArrowTopRight color={Colors.primary._600} />
      </UserTitle>
      <UserDetails>
        {map(getUserDetails(user as SearchUserRead), (d) => (
          <UserDetail key={d.detailName} detailName={d.detailName} detail={d.detail} />
        ))}
      </UserDetails>
    </RootStyle>
  );
};

export default UserPreview;
